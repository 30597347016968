/* SecularNews.css */
.secNewsTitle {
  color: black;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 2rem;
  transition: color 0.3s;
}

.secNewsTitle:hover {
  color: blue;
}

.secNewsDate {
  display: flex;
  margin-top: 2rem;
  align-items: center;
  color: grey;
  font-size: 0.75rem;
}

.secNewsDateIcon {
  margin-right: 4px;
  font-size: 0.875rem;
}

.secNewsDescription {
  margin-top: 1rem;
  text-align: left;
  color: #424242;
}

.adImage {
  width: 100%;
}

.divider {
  background-color: #f5f5f5;
  margin-top: 2px;
}

.news-date {
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: grey;
  font-size: 0.7rem;
}

/* Fix for XS screen layout */
@media (max-width: 600px) {
  /* Adjust margin and padding to prevent the shake */
  .adImage {
    display: none; /* Hide the ad on xs screens to prevent layout shift */
  }

  .news-date {
    margin-top: 10px;
    font-size: 0.6rem; /* Reduce font size to fit content */
  }

  .secNewsTitle {
    font-size: 1rem; /* Adjust title size on small screens */
  }

  /* Ensure the grid doesn't overflow */
  .MuiGrid-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

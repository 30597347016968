/* General container styling */
.courses-container {
  margin-top: -4px; /* Reduced top margin */
  padding: 0 -4px 40px; /* Correct left-right padding and bottom padding */
}

/* Header styling */
.header {
  color: black; /* Updated color */
  margin-bottom: 20px; /* Reduced bottom margin */
  font-weight: bold;
}

/* Card styling */
.course-card {
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  overflow: hidden;
}

.course-card:hover {
  transform: scale(1.05);
}

/* Image styling */
.course-image {
  height: 200px;
  object-fit: cover;
}

/* Typography styling */
.course-name {
  color: black; /* Updated color */
  
}

.course-description {
  color: black; /* Updated color */
 
 
}

.course-date {
  color: black; /* Updated color */
}

/* Button styling */
.join-button {
  background-color: #0a2240; /* Updated color to match button in JSX */
  color: white;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
}

/* General Styling */
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

/* Ensure the content does not exceed screen size */
.container {
  width: 100%;
  overflow-x: hidden;
}

/* Ensure Grid Layout does not cause overflow issues */
.grid-container {
  display: grid;
  grid-template-columns: 1fr 3fr; /* Left side and right side */
  gap: 16px;
  margin-bottom: 16px;
  overflow-x: hidden; /* Prevents any horizontal scroll */
}

.grid-left,
.grid-right {
  height: 100%;
}

/* Ensure no extra space on the right side */
.grid-left {
  overflow: hidden;
}

/* For book promo - Fix any padding and margin causing shifts */
.book-promo {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
 
  transition: transform 0.3s ease-in-out;
  margin-top: 26px; /* Adjust to prevent extra space at the top */
}

/* Prevent Book Promo from expanding too much */
.book-cover {
  width: 100%;
  height: auto;
  display: block;
}

.book-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 24px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.book-overlay:hover {
  opacity: 1;
}

/* Grid Layout Fix for smaller screen */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
  
  .book-promo {
    margin-top: 16px; /* Ensure no extra space between content */
  }
}

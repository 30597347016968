.card-container {
  margin-bottom: 1px;
  margin-left: 1.8%;
  margin-right: 1.8%;
}

.card {
  box-shadow: none;
  border-radius: 5px;
  padding: 24px;
  background-color: #ffffff;
}

.button-box {
  border: none;
  border-radius: 8px;
  padding: 4px;
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: -4px;
}

.button-box:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.button-link {
  text-decoration: none;
  color: white;
  display: block;
}

.button-content {
  display: flex;
  align-items: center;
}

.button-image {
  width: 80px;
  height: 60px;
  margin-right: 16px;
  object-fit: cover;
  border-radius: 4px;
}

.button-text {
  font-weight: bold;
  font-size: 18px;
}

/* Button color variations */
.button-primary {
  background-color:  #103c72; /* Dark blue */
}

.button-secondary {
  background-color:#103c72; /* Medium blue */
}

.button-tertiary {
  background-color: #103c72; /* Light blue */
}

.button-quaternary {
  background-color:  #103c72; /* Sky blue */
}

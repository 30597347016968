/* Magazine Card styling */
.magazine-card {
  position: relative;
  background-color: #ffffff; /* White background */
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  max-width: 325px;  /* Reduce width of card */
  height: auto;  /* Let height adjust to content */
}

.magazine-card:hover {
  transform: scale(1.05); /* Light zoom on hover */
}

/* Front side of the card */
.card-inner {
  display: flex;
  flex-direction: column;
}

.card-front {
  padding: 15px; /* Reduced padding for a smaller card */
}

.card-header h2 {
  font-size: 1.25em;  /* Smaller font size for the month */
  color: #2c3e50; /* Dark text for the month */
}

.card-header span {
  font-size: 1.1em;  /* Slightly smaller font size for the year */
  color: #95a5a6; /* Lighter grey text for the year */
}

.card-theme h3 {
  font-size: 1.2em;
  color: #16a085; /* Soft teal for the theme */
  margin-top: 10px;
}

/* Adjusted image size */
.card-image {
  width: 100%;
  height: 180px; /* Reduced height to make the card shorter */
  position: relative;
  overflow: hidden;
  margin-top: 10px; /* Reduced margin */
}

.card-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image still covers the space without stretching */
  border-radius: 8px;
}

/* Back side of the card */
.card-back {
  padding: 15px;  /* Reduced padding for the back */
  background-color: #f0f4f5; /* Light grey background for back */
  color: #34495e; /* Darker text for description */
  text-align: center;
}

.card-description p {
  font-size: 1em;
  color: #7f8c8d; /* Light grey text */
}

.download-btn {
  margin-top: 15px; /* Reduced margin */
  padding: 8px 16px;  /* Reduced padding for a smaller button */
  background-color: #3498db; /* Blue button */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.download-btn:hover {
  background-color: #2980b9; /* Darker blue on hover */
}

/* Magazine grid container */
.magazine-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));  /* Create a responsive grid */
  gap: 40px;  /* Reduced gap between cards */
  justify-items: center;  /* Center the items horizontally inside the grid */
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;  /* Ensure it takes the full width */
  max-width: 1200px; /* Optional: Max width to prevent it from becoming too wide */
  margin-left: auto;
  margin-right: auto;
}

/* Styles for each magazine card */
.magazine-card {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the card content */
  width: 100%;
  height: 100%;
  
}

/* Style for the download button */
.download-btn {
  background-color: #AD62AA;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
}

.download-btn:hover {
  background-color: #04049a;
}

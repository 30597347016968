/* Global Reset for Box Sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Prevent horizontal scrolling */
body {
  overflow-x: hidden;
  background-color: #f5f5f5;
}

/* Main news container */
.main-news {
  width: 100%;
  position: relative;
  overflow: hidden; /* Prevent overflow */
}

/* News image */
.news-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Make sure images cover the space */
}

/* News box styling */
.news-box {
  position: relative;
  margin-bottom: 76px; /* Add unit to margin */
  color: white;
  
  padding: 16px; /* Add padding to prevent content from touching edges */
}

/* Main news title */
.mainnews {
  font-size: 1.3rem;
}

/* Title hover effect */
.news-title {
  text-align: left;
  transition: color 0.3s;
}

.news-title:hover {
  color: #007aff; /* Change to blue on hover */
}

/* Subtitle hover effect */
.news-subtitle {
  text-align: left;
  transition: color 0.3s;
}

.news-subtitle:hover {
  color: #007aff; /* Change to blue on hover */
}

/* Date styling */
.news-date {
  display: flex;
  align-items: center;
  color: grey;
  font-size: 0.75rem;
}

/* Divider for news */
.news-divider {
  background-color: #f5f5f5;
  margin-top: 2px;
}

/* Prevent horizontal overflow in the grid container */
.grid-container {
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Fix for small screens (xs) */
@media (max-width: 600px) {
  .news-image {
    width: 100%;
    height: auto; /* Adjust image height for small screens */
  }

  .mainnews {
    font-size: 1.3rem;
  }

  .news-box {
    margin-bottom: 16px; /* Reduce margin to avoid extra space */
    padding: 10px; /* Add padding for small screens */
  }

  .news-title, .news-subtitle {
    font-size: 1rem; /* Adjust font size for small screens */
  }
}
